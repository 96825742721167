import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/20/solid';
import Title from '../sidebar/Title';
import { ArrowUpIcon } from '@heroicons/react/24/outline';
import { Unit, Version } from '../../types/bom';
import axios from 'axios';

function RetrofitUnitForm({ unit, version, onClose }: { unit: Unit, version: Version, onClose: () => void }) {
  const [note, setNote] = useState<string>("");

  const handleRetrofit = () => {
    axios.post(`/unit/${unit.serial}/retrofit/`, { note: note, diff: version.id })
      .then(() => {
        // Call the onClose function to close the modal on a successful response
        onClose();
      })
      .catch((error) => {
        console.error("Error retrofitting unit:", error);
        // Optionally handle the error state here, e.g., show a notification to the user
      });
  };

  return (
    <>
      <div className="flex flex-row gap-2 items-center">
        <ArrowUpIcon
          className="h-5 w-5 text-gray-400 dark:text-gray-500 transition-colors"
          aria-hidden="true"
        />
        <Title title="Retrofit unit" />
      </div>
      <div className="mt-3 sm:mt-5">
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Enter additional instructions, if any:
          </label>
          <textarea
            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-48"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="submit"
            className='rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset bg-white text-gray-900 ring-gray-300 hover:bg-gray-50'
            onClick={handleRetrofit}
          >
            Retrofit unit
          </button>
        </div>
      </div>
    </>
  )
};

export default function RetrofitUnit({ open, onClose, unit, version }: { open: boolean, onClose: () => void, unit: Unit, version: Version }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <RetrofitUnitForm unit={unit} version={version} onClose={onClose} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
