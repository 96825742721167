import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-tailwind/react";
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST || 'https://gettracely.com/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
  request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access');
  return request;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      // Handle token refresh
      return axios
        .post('/account/refresh/', {
          refresh: localStorage.getItem('refresh'),
        })
        .then(response => {
          localStorage.setItem('access', response.data.access);
          // Update the original request with the new access token
          error.config.headers['Authorization'] = `Bearer ${response.data.access}`;
          return axios.request(error.config); // Retry the original request
        })
        .catch(error => {
          // Handle refresh failure
          localStorage.removeItem('access');
          localStorage.removeItem('refresh');
          window.location.href = '/login';
          return Promise.reject(error); // Reject the promise after failed refresh
        });
    }
    return Promise.reject(error); // Reject promise for all other errors
  }
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);
