import { createContext, useState, useEffect, ReactNode } from 'react';
import { Change, Line, Unit, Version } from '../../types/bom';
import axios from 'axios';

type ContextProps = {
    version?: Version;
    compareToVersion?: Version;
    unit?: Unit;
    compareToUnit?: Unit;
    // assemblies?: Assembly[];
    selectVersion: (v?: Version) => void;
    selectCompareToVersion: (v?: Version) => void;
    selectUnit: (u?: Unit) => void;
    selectCompareToUnit: (u?: Unit) => void;
    bom: Line[];
};

export const BomContext = createContext<ContextProps>({
    selectVersion: () => { },
    selectCompareToVersion: () => { },
    selectUnit: () => { },
    selectCompareToUnit: () => { },
    bom: [],
});

export const BomProvider = ({ children }: { children: ReactNode }) => {
    const [version, setVersion] = useState<Version | undefined>(undefined);
    const [compareToVersion, setCompareToVersion] = useState<Version | undefined>(undefined);
    const [unit, setUnit] = useState<Unit | undefined>(undefined);
    const [compareToUnit, setCompareToUnit] = useState<Unit | undefined>(undefined);
    // const [assemblies, setAssemblies] = useState<Assembly[] | undefined>(undefined);
    const [bom, setBom] = useState<Line[]>([]);

    const getBom = async () => {
        const diff_1 = version?.id || unit?.diff;
        const diff_2 = compareToVersion?.id || compareToUnit?.diff;

        let queryParams = `project_id=2`;

        if (!(diff_1 && diff_2)) {
            if (diff_1) {
                queryParams += `&diff_id=${diff_1}`;
            }
            axios.get(`/bom/?${queryParams}`)
                .then((res) => setBom(res.data))
                .catch((err) => console.error(err));
        } else if (diff_1 && diff_2) {
            queryParams += `&diff1_id=${diff_1}&diff2_id=${diff_2}`;
            axios.get(`/diff/?${queryParams}`)
                .then((res) => setBom(res.data))
                .catch((err) => console.error(err));
        }
    };

    useEffect(() => {
        getBom();
    }, [version, compareToVersion, unit, compareToUnit]);

    const context = {
        version: version,
        compareToVersion: compareToVersion,
        unit: unit,
        compareToUnit: compareToUnit,
        // assemblies: assemblies,
        selectVersion: setVersion,
        selectCompareToVersion: setCompareToVersion,
        selectUnit: setUnit,
        selectCompareToUnit: setCompareToUnit,
        bom: bom,
    };

    return (
        <BomContext.Provider value={context}>
            {children}
        </BomContext.Provider>
    );
};
