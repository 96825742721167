import Moment from 'moment';
import Spinner from '../misc/Spinner';
import { WorkOrder } from '../../types/bom';

function HistoryItem({ work_order }: { work_order: WorkOrder }) {
    const { author, completed, url, name } = work_order;
    const authorName = `${author.first_name} ${author.last_name}`;

    return (
        <li className="relative mb-10">
            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className="flex flex-row mt-1 ml-4 justify-between">
                <a
                    href={url}
                    className="text-sm leading-6 text-gray-500 dark:text-gray-400 ml-2"
                    target="_blank"
                    rel="noreferrer"
                >
                    {name}
                </a>
                <time className="text-sm leading-6 text-gray-500 dark:text-gray-400 ml-2">
                    {Moment(completed).format('MMM D')}
                </time>
            </div>
        </li>
    );
}

export default function History({ work_orders }: { work_orders: WorkOrder[] }) {
    return (
        <div className="py-4">
            <div className="flex flex-row items-center gap-2">
                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-white hover:underline">
                    <a
                        href={"https://github.com/Pattern-Labs/hardware/issues"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Timeline
                    </a>
                </h3>
            </div>
            <ol className="mt-4 relative border-s border-gray-200 dark:border-gray-700">
                {work_orders && work_orders.map((work_order) => (
                    <HistoryItem key={work_order.id} work_order={work_order} />
                ))}
                {work_orders === undefined && <Spinner />}
                {work_orders?.length === 0 &&
                    <p className="text-sm text-gray-500 dark:text-gray-400 py-4">No work order history available.</p>
                }
            </ol>
        </div>
    );
}
