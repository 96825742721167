import { useContext, useState } from "react";
import Select from "../misc/Select";
import UserMenu from "./UserMenu";
import { Project } from "../../types/project";
import { UserContext } from "../auth/UserProvider";

const project_options = [
    { id: '1', value: 'Pathfinder' },
];

export default function Navbar() {
    const context = useContext(UserContext);
    const user = context.user;

    const [project, setProject] = useState<Project>({ id: 1, name: 'Pathfinder' });

    const location = window.location.pathname;
    if (location === '/login') return null;

    return (
        <div className="flex flex-row justify-between bg-gray-100 dark:bg-gray-800 p-4 drop-shadow-sm">
            <div className="flex flex-row gap-4">
                <img src="/pattern.png" alt="logo" className="h-8 w-auto" />
                <Select
                    label="Select project"
                    options={project_options}
                    selected={project_options.find(option => Number(option.id) === project?.id) || undefined}
                    onChange={(e) => setProject(e.id)}
                />
            </div>
            <UserMenu />
        </div>
    );
}
