import { ReactNode, createContext, useEffect, useState } from "react";
import { User } from "../../types/user";
import axios from "axios";

type ContextProps = {
    user?: User;
};

export const UserContext = createContext<ContextProps>({
    user: undefined,
});

export const UserProvider = ({ children } : { children: ReactNode }) => {
    const [user, setUser] = useState<User | undefined>(undefined);

    const getUser = async () => {
        await axios.get('/user/')
            .then((res) => setUser(res.data))
            .catch((err) => console.error(err));
    }

    useEffect(() => {
        if (!user && !window.location.pathname.includes('/login')) {
            getUser();
        }
    }, [user]);

    const context = {
        user: user,
    };

    return (
        <UserContext.Provider value={context}>
            {children}
        </UserContext.Provider>
    );
};
