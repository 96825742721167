import { useContext } from "react";
import { BomContext } from "../bom/BomProvider";
import VersionView from "./VersionView";
import UnitView from "./UnitView";
// import VersionView from "./VersionView";
// import BuildView from "./BuildView";
// import DesignView from "./DesignView";

export default function Sidebar() {
    // const { version, build } = useContext(BomContext);
    const { version, unit } = useContext(BomContext);

    return (
        <div className="w-2/5 overflow-y-scroll h-full">
            {version && <VersionView version={version} />}
            {unit && <UnitView unit={unit} />}
            {/* {!version && !build && <DesignView />} */}
        </div>
    );
};
