import { Menu, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../auth/UserProvider";

function MenuItem({ onClick, text }: { onClick: () => void, text: string }) {
    return (
        <Menu.Item>
            {({ active }) => (
                <button
                    onClick={onClick}
                    className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700 w-full text-left`}
                >
                    {text}
                </button>
            )}
        </Menu.Item>
    );
};

export default function UserMenu() {
    const context = useContext(UserContext);
    const user = context.user;

    const [dark, setDark] = useState(localStorage.getItem('theme') === 'dark');

    const toggleTheme = () => {
        if (dark) {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('theme', 'light');
        } else {
            document.documentElement.classList.add('dark');
            localStorage.setItem('theme', 'dark');
        }
        setDark(!dark);
    };

    const signOut = () => {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        window.location.href = '/login';
    };

    useEffect(() => {
        if (dark) {
            document.documentElement.classList.add('dark');
        }
    }, [dark]);

    return (
        <Menu as="div" className="relative ml-5 flex-shrink-0">
            <div>
                <Menu.Button className="relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 focus:ring-offset-2">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    {/* <img className="h-8 w-8 rounded-full" src={user?.userdata.photo_url} alt="" /> */}
                    <img className="h-8 w-8 rounded-full" src="/default_pfp.jpg" alt="" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <MenuItem onClick={toggleTheme} text={dark ? "Switch to light" : "Switch to dark"} />
                    <MenuItem onClick={signOut} text="Sign out" />
                </Menu.Items>
            </Transition>
        </Menu>
    );
};
