import { useContext, useState } from "react";
import { BomContext } from "./BomProvider";
import axios from "axios";
import Select from "../misc/Select";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Unit, Version } from "../../types/bom";
import { Build } from "../../types/build";
import SelectNew from "../misc/SelectNew";
import CreateBuild from "../dialogs/CreateUnit";
import RetrofitUnit from "../dialogs/RetrofitUnit";
import UploadBom from "../dialogs/UploadBom";

type Option = {
    id: string;
    value: string;
};

export default function Filters({ versions, units }: { versions: Version[], units: Unit[] }) {
    const context = useContext(BomContext);
    const {
        version,
        compareToVersion,
        unit,
        compareToUnit,
        selectVersion,
        selectCompareToVersion,
        selectUnit,
        selectCompareToUnit
    } = context;

    const [diff, setDiff] = useState<boolean>(false);
    const [openRetrofit, setOpenRetrofit] = useState<boolean>(false);
    const [openUploadBom, setOpenUploadBom] = useState<boolean>(false);

    const getVersion = (id: number) => {
        axios.get(`/version/?diff_id=${id}`).then(response => {
            selectVersion(response.data);
        }).catch(error => {
            console.log(error);
        });
    };

    const getCompareToVersion = (id: number) => {
        axios.get(`/version/?diff_id=${id}`).then(response => {
            selectCompareToVersion(response.data);
        }).catch(error => {
            console.log(error);
        });
    };

    const getUnit = (serial: string) => {
        axios.get(`/unit/${serial}/`).then(response => {
            selectUnit(response.data);
        }).catch(error => {
            console.log(error);
        });
    };

    const getCompareToUnit = (serial: string) => {
        axios.get(`/unit/${serial}/`).then(response => {
            selectCompareToUnit(response.data);
        }).catch(error => {
            console.log(error);
        });
    };

    const versionOptions: Option[] = versions.map(v => { return { id: v.id.toString(), value: v.tag } });
    const unitOptions: Option[] = units.map(u => { return { id: u.serial, value: u.serial } });

    return (
        <div className="flex flex-row justify-between">
            <div className="flex flex-col space-y-2 px-4">
                <div className="flex flex-row gap-2">
                    {!unit &&
                        <SelectNew
                            label="Select version"
                            options={versionOptions}
                            selected={versionOptions.find(option => Number(option.id) === version?.id) || undefined}
                            onChange={(e) => e ? getVersion(Number(e.id)) : selectVersion(undefined)}
                        />
                    }
                    {!version &&
                        <SelectNew
                            label="Select unit"
                            options={unitOptions}
                            selected={unitOptions.find(option => option.id === unit?.serial) || undefined}
                            onChange={(e) => e ? getUnit(e.id) : selectUnit(undefined)}
                        />
                    }
                </div>
                <div className="flex flex-row gap-2 items-center">
                    {(version || unit) && !diff &&
                        <>
                            <PlusCircleIcon
                                className="h-5 w-5 text-gray-400 dark:text-gray-500 cursor-pointer hover:text-gray-500 dark:hover:text-gray-400 transition-colors"
                                aria-hidden="true"
                                onClick={() => setDiff(!diff)}
                            />
                            <p className="text-sm font-medium text-gray-400 dark:text-gray-500 italic">Diff</p>
                        </>
                    }
                    {(version || unit) && diff &&
                        <>
                            <p className="text-sm font-medium text-gray-400 dark:text-gray-500 italic">Compare to...</p>
                            {!compareToUnit &&
                                <SelectNew
                                    label="Select version"
                                    options={versionOptions.filter(option => Number(option.id) < Number(version?.id || 999999999999) || Number(option.id) < Number(unit?.diff || 999999999))}
                                    selected={versionOptions.find(option => Number(option.id) === compareToVersion?.id) || undefined}
                                    onChange={(e) => e ? getCompareToVersion(e.id) : selectCompareToVersion(undefined)}
                                />
                            }
                            {!compareToVersion &&
                                <SelectNew
                                    label="Select unit"
                                    options={unitOptions}
                                    selected={unitOptions.find(option => option.id === compareToUnit?.serial) || undefined}
                                    onChange={(e) => e ? getCompareToUnit(e.id) : selectCompareToUnit(undefined)}
                                />
                            }
                        </>
                    }
                </div>
            </div>
            <div className="p-4">
                {version && compareToUnit && version.id !== compareToUnit.design && compareToUnit.work_orders.findIndex(wo => wo.design === version.id) === -1 &&
                    <>
                        <button
                            className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset bg-white text-blue-900 ring-blue-300 bg-blue-50 hover:bg-blue-100"
                            onClick={() => setOpenRetrofit(true)}
                        >
                            Retrofit unit
                        </button>
                        <RetrofitUnit open={openRetrofit} onClose={() => setOpenRetrofit(false)} unit={compareToUnit} version={version} />
                    </>
                }
                {!version && !unit && !compareToVersion && !compareToUnit &&
                    <>
                        <button
                            className="rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset bg-white text-green-900 ring-green-300 hover:bg-green-50"
                            onClick={() => setOpenUploadBom(true)}
                        >
                            Upload BOM
                        </button>
                        <UploadBom open={openUploadBom} onClose={() => setOpenUploadBom(false)} />
                    </>
                }
            </div>
        </div>
    );
};
