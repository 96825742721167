import Moment from 'moment';
import Title from './Title';
import { Build } from '../../types/build';
import { ClipboardDocumentIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import CreateBuild from '../dialogs/CreateUnit';
import { Unit, Version } from '../../types/bom';
import CreateUnit from '../dialogs/CreateUnit';

function UnitItem({ unit }: { unit: Unit }) {
    const { serial } = unit;
    // const created = Moment(build.created).format('d MMM');

    return (
        <div className="flex flex-row justify-between py-4">
            <div className="flex flex-col gap-1">
                <p className="text-sm leading-6 text-gray-500">{unit.name}</p>
                {/* {build.service?.length > 0 &&
                    <p className="text-sm leading-6 text-orange-400 ml-2 italic">{build.service.length} service task{build.service.length > 1 && 's'}</p>
                } */}
            </div>
            <div className="flex flex-col gap-1">
                <div className="flex flex-row gap-2">
                    <span className="text-sm font-medium text-gray-400 dark:text-gray-500">{serial}</span>
                    <ClipboardDocumentIcon
                        className="h-5 w-5 text-gray-400 dark:text-gray-500 cursor-pointer hover:text-gray-500 dark:hover:text-gray-400 transition-colors"
                        aria-hidden="true"
                        onClick={() => navigator.clipboard.writeText(serial)}
                    />
                </div>
                {/* <p className="text-sm leading-6 text-gray-400 ml-2 italic text-right">created on {created}</p> */}
            </div>
        </div>
    );
}

export default function Units({ units, version }: { units: Unit[], version: Version }) {
    const [openCreate, setOpenCreate] = useState<boolean>(false);

    return (
        <div className="py-4">
            <div className="flex flex-row items-center justify-between">
                <Title title="Units" />
                <PlusIcon
                    className="h-5 w-5 text-gray-400 dark:text-gray-500 cursor-pointer hover:text-gray-500 dark:hover:text-gray-400 transition-colors"
                    aria-hidden="true"
                    onClick={() => setOpenCreate(true)}
                />
                <CreateUnit open={openCreate} onClose={() => setOpenCreate(false)} version={version} />
            </div>
            <dl className="divide-y divide-gray-200 dark:divide-gray-700">
                {units.map((unit) => (
                    <UnitItem unit={unit} />
                ))}
                {!units.length &&
                    <p className="text-sm mt-4 leading-6 text-gray-400 dark:text-gray-500">No units found</p>
                }
            </dl>
        </div>
    );
}
