import Table from './Table';
import Filters from './Filters';
import { Project, Unit, Version } from '../../types/bom';
import { Build } from '../../types/build';
import { useEffect, useState } from 'react';
import axios from 'axios';

export default function Bom() {
    const [project, setProject] = useState<Project | undefined>(undefined);

    const getProject = async () => {
        await axios.get('/project/2/')
            .then((res) => setProject(res.data))
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        if (!project) {
            getProject();
        }
    }, [project]);

    return (
        <div className="h-full max-w-full mt-4">
            {project && <Filters versions={project.versions} units={project.units} />}
            <Table />
        </div>
    );
}
