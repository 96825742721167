import { Routes, Route } from 'react-router-dom';

import BomPage from './pages/BomPage';
import LoginPage from './pages/LoginPage';

const Main = () => {
    return (
        <Routes>
            <Route path='/' element={<BomPage />} />
            <Route path='/login' element={<LoginPage />} />
        </Routes>
    );
}

export default Main;
