function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export interface Option {
    id: string;
    value: string;
}

export interface SelectProps {
    label: string;
    options: Option[];
    selected?: Option;
    onChange: (e: any) => void;
}

export default function SelectNew({ label, options, selected, onChange }: SelectProps) {
    return (
        <div>
            <select
                id="location"
                name="location"
                value={selected?.value || ''}
                onChange={(e) => {
                    const selectedOption = options.find(option => option.value === e.target.value);
                    onChange(selectedOption);
                }}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
                {/* Placeholder option */}
                <option value="">{label}</option>
                {options.map((option) => (
                    <option key={option.id} value={option.value}>
                        {option.value}
                    </option>
                ))}
            </select>
        </div>
    );
}
