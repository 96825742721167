import Moment from 'moment';
import { ClipboardDocumentIcon } from '@heroicons/react/20/solid';
import Title from './Title';
import { Build } from '../../types/build';
import { Unit } from '../../types/bom';

function KeyValue({ name, value }: { name: string, value: string }) {
    return (
        <div className="flex flex-row justify-between px-4 py-4 sm:gap-4 sm:px-0 dark:bg-gray-800">
            <dt className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">{name}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300 sm:col-span-2 sm:mt-0">{value}</dd>
        </div>
    );
}

export default function UnitSummary({ unit }: { unit: Unit }) {
    const { name, serial } = unit;

    return (
        <div className="py-4 gap-4 dark:bg-gray-900">
            <div className="flex flex-row items-center gap-2 justify-between">
                <Title title={name} />
                <div className="flex flex-row gap-2">
                    <span className="text-sm font-medium text-gray-400 dark:text-gray-500">{serial}</span>
                    <ClipboardDocumentIcon
                        className="h-5 w-5 text-gray-400 dark:text-gray-500 cursor-pointer hover:text-gray-500 dark:hover:text-gray-400 transition-colors"
                        aria-hidden="true"
                        onClick={() => navigator.clipboard.writeText(serial)}
                    />
                </div>
            </div>
            <dl className="divide-y divide-gray-200 dark:divide-gray-700">
                {/* <KeyValue name="Version" value={version.num} />
                <KeyValue name="Created on" value={created} />
                <KeyValue name="Created by" value={creator_name} /> */}
            </dl>
        </div>
    );
}
