import Moment from 'moment';
import Title from './Title';
import { Version } from '../../types/bom';
// import { Version } from '../../types/bom';

function KeyValue({ name, value }: { name: string, value: string }) {
    return (
        <div className="flex flex-row justify-between px-4 py-4 sm:gap-4 sm:px-0 dark:bg-gray-800">
            <dt className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">{name}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300 sm:col-span-2 sm:mt-0">{value}</dd>
        </div>
    );
};

export default function VersionSummary({ version }: { version: Version }) {
    const created = Moment(version.created).format('MMM d, YYYY');
    const author_name = `${version.author.first_name} ${version.author.last_name}`;

    return (
        <div className="py-4 gap-4 dark:bg-gray-900">
            <Title title={`Version ${version.tag}`} />
            <dl className="divide-y divide-gray-200 dark:divide-gray-700">
                <KeyValue name="Created on" value={created} />
                <KeyValue name="Created by" value={author_name} />
            </dl>
        </div>
    );
};
