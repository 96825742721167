import Builds from './Units'
import Issues from './WorkOrders'
// import Services from './Services';
// import VersionSummary from './VersionSummary';
import { Status } from '../../types/issue';
import { Version } from '../../types/bom';
import VersionSummary from './VersionSummary';
import Units from './Units';
// import { Version } from '../../types/bom';

export default function VersionView({ version }: { version: Version }) {
    return (
        <div className="p-4 bg-gray-100 dark:bg-gray-800 divide-y divide-gray-400 dark:divide-gray-500 h-full">
            <VersionSummary version={version} />
            <Units units={version.units} version={version} />
        </div>
    )
}
