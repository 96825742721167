import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/20/solid';
import Title from '../sidebar/Title';
import axios from 'axios';
import { Version } from '../../types/bom';

function CreateUnitForm({ version, onClose }: { version: Version, onClose: () => void }) {
  const [num, setNum] = useState<number | undefined>(undefined);
  const [names, setNames] = useState<string[]>([]);

  const updateNum = (n: number | undefined) => {
    setNum(n);
    if (!n || n < 1 || n > 10) return;
    setNames(Array.from(Array(n).keys()).map(() => ''));
  };

  useEffect(() => {

  }, [num]);

  const createUnits = () => {
    if (!num || num < 1 || num > 10 || names.some((name) => name.length === 0)) return;

    const namesMerged = names.join(',') + ',';

    axios.post('/unit/', {
      num: num,
      names: namesMerged,
      diff: version.id
    }).then(response => {
      onClose();
    });
  }

  return (
    <>
      <div className="flex flex-row gap-2 items-center">
        <PlusIcon
          className="h-5 w-5 text-gray-400 dark:text-gray-500 transition-colors"
          aria-hidden="true"
        />
        <Title title="New units" />
      </div>
      <div className="mt-3 sm:mt-5">
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            How many units?
          </label>
          <input
            type="number"
            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={num}
            onChange={(e) => updateNum(e.target.value.length > 0 ? parseInt(e.target.value) : undefined)}
          />
          {!num &&
            <p className="text-sm text-gray-500">Enter the number of units, and then you can name them.</p>
          }
          {num && num >= 10 &&
            <p className="text-sm text-gray-500">Please enter a number less than 10.</p>
          }
        </div>
        {num && 1 <= num && num <= 10 &&
          <div className="flex flex-col gap-2 mt-2">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Give each unit a name:
            </label>
            {Array.from(Array(num).keys()).map((i) => (
              <input
                key={i}
                type="text"
                className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={names[i]}
                onChange={(e) => {
                  const newNames = [...names];
                  newNames[i] = e.target.value;
                  setNames(newNames);
                }}
              />
            ))}
          </div>
        }
        <div className="mt-5 sm:mt-6">
          <button
            type="submit"
            className={`rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ${!num || num < 1 || num > 10 || names.some((name) => name.length === 0)
              ? 'bg-gray-200 text-gray-400 ring-gray-200'
              : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50'
              }`}
            disabled={!num || num < 1 || num > 10 || names.some((name) => name.length === 0)}
            onClick={createUnits}
          >
            Create units
          </button>
        </div>
      </div>
    </>
  )
};

export default function CreateUnit({ open, onClose, version }: { open: boolean, onClose: () => void, version: Version }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <CreateUnitForm version={version} onClose={onClose} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
