import Main from "./Main";
import Navbar from "./components/nav/Navbar";
import { UserProvider } from "./components/auth/UserProvider";

export default function App() {
  return (
    <div className="flex flex-col h-screen">
      <UserProvider>
        <Navbar />
        <Main />
      </UserProvider>
    </div>
  );
}
