import axios from 'axios';
import { Button, Card, Input, Typography } from "@material-tailwind/react";
import { useState } from "react";

export default function Login() {

    const [hasAccount, setHasAccount] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = (e?: any) => {
        // Separate instance so it doesn't add auth header
        const instance = axios.create();

        instance.post('/account/access/', {
            email: email,
            password: password
        }).then(response => {
            localStorage.setItem('access', response.data.access);
            localStorage.setItem('refresh', response.data.refresh);
            window.location.href = '/';
        }).catch(error => {
            console.log(error);
        });
    };

    const register = (e: any) => {
        // Separate instance so it doesn't add auth header
        const instance = axios.create();

        instance.post('/account/register/', {
            email: email,
            password: password
        })
            .then(login)
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <Card color="transparent" shadow={false}>
            <Typography variant="h4" color="blue-gray">
                {hasAccount ? "Login" : "Sign Up"}
            </Typography>
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
                <div className="mb-1 flex flex-col gap-6">
                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                        Your Email
                    </Typography>
                    <Input
                        size="lg"
                        placeholder="name@mail.com"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        crossOrigin={true}
                        onChange={(e) => setEmail(e.target.value)}
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                    <Typography variant="h6" color="blue-gray" className="-mb-3">
                        Password
                    </Typography>
                    <Input
                        type="password"
                        size="lg"
                        placeholder="********"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        crossOrigin={true}
                        onChange={(e) => setPassword(e.target.value)}
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
                {/* <Checkbox
                    label={
                        <Typography
                        variant="small"
                        color="gray"
                        className="flex items-center font-normal"
                        >
                        I agree the
                        <a
                            href="#"
                            className="font-medium transition-colors hover:text-gray-900"
                        >
                            &nbsp;Terms and Conditions
                        </a>
                        </Typography>
                    }
                    crossOrigin={true}
                    containerProps={{ className: "-ml-2.5" }}
                /> */}
                <Button className="mt-6" fullWidth onClick={hasAccount ? login : register}>
                    {hasAccount ? "log in" : "sign up"}
                </Button>
                <Typography color="gray" className="mt-4 text-center font-normal">
                    {hasAccount ? "Don't have an account?" : "Already have an account?"}
                    <a className="font-medium text-gray-900" onClick={() => setHasAccount(!hasAccount)}>
                        {` `}{hasAccount ? "Sign up" : "Sign in"}
                    </a>
                </Typography>
            </form>
        </Card>
    );
}
