import Bom from '../components/bom/Bom';
import { BomProvider } from '../components/bom/BomProvider';
import Sidebar from '../components/sidebar/Sidebar';

export default function BomPage() {
    return (
        <div className="flex h-full w-screen bg-gray-50 dark:bg-gray-900">
            <BomProvider>
                <div className="w-3/5 overflow-x-scroll overflow-y-scroll">
                    <Bom />
                </div>
                <Sidebar />
            </BomProvider>
        </div>
    );
};
