import Login from "../components/auth/Login";

export default function LoginPage() {
    return (
        <div className="flex h-screen">
            <div className="m-auto">
                <Login />
            </div>
        </div>
    );
};
