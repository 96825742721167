import React, { useContext } from "react";
import { BomContext } from "./BomProvider";
import Spinner from "../misc/Spinner";
import { LinkIcon } from "@heroicons/react/24/outline";

const DESIGN_HEADERS = [
    "Part No.",
    "Summary",
    "Name",
    "Version",
    "Qty",
    "Price",
    "Note",
    "Source",
];

const UNIT_HEADERS = ["Installed"].concat(DESIGN_HEADERS);

let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

function Cell({ children }: { children: React.ReactNode }) {
    return (
        <td className="px-3 py-3.5 text-left text-sm font-normal text-gray-900 dark:text-white">
            {children}
        </td>
    );
}

export default function Table() {
    const context = useContext(BomContext);
    const { bom } = context;

    if (!bom || bom.length === 0) {
        return <Spinner />;
    }

    const is_design = bom[0].installed === null;

    const getRowClassName = (change: any) => {
        switch (change) {
            case "added":
                return "bg-green-50 dark:bg-green-800 hover:bg-green-100 dark:hover:bg-green-700";
            case "removed":
                return "bg-red-50 dark:bg-red-800 hover:bg-red-100 dark:hover:bg-red-700";
            case "changed":
                return "bg-yellow-50 dark:bg-yellow-800 hover:bg-yellow-100 dark:hover:bg-yellow-700";
            case "unchanged":
                return "bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700";
            default:
                return "bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700";
        }
    };

    return (
        <div className="overflow-x-auto">
            <div className="divide-y divide-gray-300 dark:divide-gray-700">
                <table className="min-w-full">
                    <thead>
                        <tr>
                            {(is_design ? DESIGN_HEADERS : UNIT_HEADERS).map((head) => (
                                <th
                                    scope="col"
                                    key={head}
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                                >
                                    {head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {bom.map((line, idx) => (
                            <tr
                                key={idx}
                                className={getRowClassName(line.change)}
                            >
                                {line.installed && <Cell>{new Date(line.installed).toLocaleString()}</Cell>}
                                <Cell>{line.num}</Cell>
                                <Cell>{line.summary !== "null" ? line.summary : ""}</Cell>
                                <Cell>{line.name}</Cell>
                                <Cell>{line.version}</Cell>
                                <Cell>
                                    {line.change === "changed" ? (
                                        <>
                                            <del>{line.prev_qty}</del>
                                            &nbsp;{line.curr_qty}
                                        </>
                                    ) : (
                                        line.qty
                                    )}
                                </Cell>
                                <Cell>{USDollar.format(line.price)}</Cell>
                                <Cell>{line.note}</Cell>
                                <Cell>
                                    {line.url && (
                                        <a
                                            href={line.url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <LinkIcon className="h-5 w-5" />
                                        </a>
                                    )}
                                </Cell>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
