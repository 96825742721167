import { Unit } from '../../types/bom';
import UnitSummary from './UnitSummary';
import WorkOrders from './WorkOrders';
import History from './History';

export default function UnitView({ unit }: { unit: Unit }) {
    const { name, work_orders } = unit;
    const complete = work_orders.filter(wo => wo.status === 'complete');
    const incomplete = work_orders.filter(wo => wo.status === 'incomplete');

    complete.sort((a, b) => {
        if (a.completed && b.completed) {
            return a.completed > b.completed ? -1 : 1;
        }
        return 0;
    });

    return (
        <div className="p-4 bg-gray-100 dark:bg-gray-800 divide-y divide-gray-400 dark:divide-gray-500 h-screen">
            <UnitSummary unit={unit} />
            <WorkOrders work_orders={incomplete} />
            <History work_orders={complete} />
            {/* <Issues unit={unit} /> */}
        </div>
    )
}
